import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Mock validation: Normally, you'd validate against a backend service
    /*if (email === 'user@example.com' && password === 'password') {
      navigate('/my-page-posts');
    } else {
      alert('Invalid credentials');
    }*/

      try {
        const response = await fetch('https://nodeapi.tueducaciondigital.site/loginfanpage', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
        console.log("response is", response)
  
        if (response.ok) {
          navigate('/my-page-posts');
        } else {
          alert('Invalid credentials');
        }
      } catch (error) {
        console.error('Error during login:', error);
        alert('An error occurred. Please try again.');
      }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2>Sign In</h2>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input type="checkbox" id="remember" />
          <label htmlFor="remember">Remember Me</label>
        </div>
        <button type="submit" className="login-button">Sign In</button>
        <div className="forgot-password">
          <a href="#">Forgot password? Reset it.</a>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
